import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PrestamosRestcService } from '../../client/prestamos/prestamos-restc.service';
import { Pago } from 'src/app/api/prestamo/prestamo';

@Injectable({
  providedIn: 'root'
})
export class PrestamosPsService extends BaseProcess<PrestamosRestcService>{

  rest: PrestamosRestcService;

  constructor(httpClient: HttpClient, crest: PrestamosRestcService) {
    super(httpClient, crest);
    this.rest = crest;
  }

  postergarPago(pagos: Pago): Observable<Pago[]> {
    return this.rest.postergarPago(pagos);
  }

}
