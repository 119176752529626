<div style="padding: 0.5rem;">
    <!-- {{indexMenu}} -->
        <!-- <div class="div-barra"> -->
            <p-toolbar class="p-shadow-2">
                <div class="p-toolbar-group-left">
                    <div>
                        <h3>Reportes. <ng-container *ngIf="tituloReporte && tituloReporte.trim() != ''">{{tituloReporte}}</ng-container></h3>
                    </div>
                </div>
                <div class="p-toolbar-group-right">
                    <button *ngIf="indexMenu" pButton type="button" label="Regresar" icon="pi pi-arrow-circle-left" (click)="accederMenu(null)"></button>
                </div>
            </p-toolbar>
        <!-- </div> -->
    
        <div class="p-shadow-2 div-contenedor">
    
            <ng-container [ngSwitch]="indexMenu">
                <axks-reportes-deudas *ngSwitchCase="1" (tituloReportes)="setTituloReportes($event)"></axks-reportes-deudas>       
    
                <axks-reportes-menu *ngSwitchDefault (numMenu)="accederMenu($event)" (tituloReportes)="setTituloReportes($event)"></axks-reportes-menu>
            </ng-container>
            
            
        </div>
    </div>