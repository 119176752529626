import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DescuentoInfonavit } from 'src/app/api/descuento/descuento-infonavit';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { DescuentoInfonavitRestcService } from 'src/app/client/descuento/descuento-infonavit-restc.service';

@Component({
  selector: 'axks-descuento-infonavit-form',
  templateUrl: './descuento-infonavit-form.component.html',
  styleUrls: ['./descuento-infonavit-form.component.scss']
})
export class DescuentoInfonavitFormComponent extends BaseView implements OnInit {
  @Input() item: DescuentoInfonavit;
  @Input() profile: ProfileC;
  @Input() catEmpleados: EmpleadoNombre[];
  @Input() showButtonPagos: boolean;

  @Output() emitShowButtonPagos = new EventEmitter<boolean>();

  showPagos: boolean;
  OP_MODE = OperationMode;
  yearRange: any;
  date: string;
  catFechas: any[];

  alphanumeric: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;
  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private consult: DescuentoInfonavitRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {};
    }

    if (!this.profile) {
      this.profile = this.getProfile();
    }

    this.initDates();
    this.showPagos = false;

    if (this.item.idEmpleado) {
      this.validarUsuario(this.item.idEmpleado);
    }
    else {
      this.showButtonPagos = false;
    }
  }

  initDates() {
    let year = new Date().getFullYear();
    this.yearRange = (year - 20) + ':' + (year + 10);
  }

  fecha(event) {
    var fechamesanio = event + ''
    let str = fechamesanio;
    const fecha = str.split("/");
    this.item.mes = fecha[0];
    this.item.anio = fecha[1];
  }

  validarUsuario(idEmpleado: number) {
    if (this.operationMode != this.OP_MODE.SEARCH) {
      this.consult.consultarFecha(idEmpleado).subscribe(
        (data) => {
          this.catFechas = data;
          this.showButtonPagos = true;
          this.emitShowButtonPagos.emit(true);
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Empleado no valido ', detail: error.error.message });
          this.showButtonPagos = false;
          this.emitShowButtonPagos.emit(false);
        }
      );
    }
  }

  configFecIni(event) {
    let pipe = new DatePipe('es-MX');
    this.item.fecIni = pipe.transform(event, 'yyyy/MM/dd', 'America/Mexico_City');
  }

  configFecFin(event) {
    let fec = event as Date;
    fec.setMonth(fec.getMonth() + 1);
    fec.setDate(fec.getDate() - 1);

    this.item.fecFin = new DatePipe('es-MX').transform(fec, 'yyyy/MM/dd', 'America/Mexico_City');
  }

}