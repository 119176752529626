import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { TipoDescuento } from '../../api/descuento/descuento';

@Injectable({
  providedIn: 'root'
})
export class TipoDescuentoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msTipoDesc + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths{
    let restPaths = super.getRestPaths();
    
    restPaths.detail = "";
  
    return restPaths;
  }
}
