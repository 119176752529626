import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DescuentoImss, Pago } from 'src/app/api/descuento/descuento-imss';
import { DescuentoImssRestcService } from 'src/app/client/descuento/descuento-imss-restc.service';

@Component({
  selector: 'axks-descuento-imss-pagos',
  templateUrl: './descuento-imss-pagos.component.html',
  styleUrls: ['./descuento-imss-pagos.component.scss']
})
export class DescuentoImssPagosComponent implements OnInit {

  @Input() item: DescuentoImss;
  @Input() profile: ProfileC;
  @Input() catFechas: string[];
  @Input() showDialog: boolean;

  @Output() emitShowDialog = new EventEmitter<boolean>();

  constructor(protected messageService: MessageService) { }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        pagos: []
      };
    }

    if (!this.catFechas) {
      this.catFechas = [];
    }
  }

  insertar() {
    if (this.item.idEmpleado) {
      this.item.pagos.push({});
    }
    else {
      this.messageService.add({
        severity: 'warn',
        summary: "Información faltante",
        detail: "No se ha seleccionado un empleado valido"
      });
    }
  }

  quitar(item: Pago) {
    this.item.pagos.splice(this.item.pagos.indexOf(item), 1);
  }

  ocultarDialog() {
    this.showDialog = false;
    this.emitShowDialog.emit(false);
  }

}
