import { CurrencyPipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DescuentoInfonavit, DescuentoInfonavitReg } from 'src/app/api/descuento/descuento-infonavit';
import { MesPipePipe } from 'src/app/pipe/mes-pipe.pipe';
import { DescuentoInfonavitPsService } from 'src/app/procs/descuento/descuento-infonavit-ps.service';

@Component({
  selector: 'axks-descuento-infonavit-empleado',
  templateUrl: './descuento-infonavit-empleado.component.html',
  styleUrls: ['./descuento-infonavit-empleado.component.scss']
})
export class DescuentoInfonavitEmpleadoComponent extends BaseMainComponent<DescuentoInfonavitPsService> implements OnInit {

  tabDefs: TabDefinition[];

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: DescuentoInfonavitPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initTabDefs() {
    this.tabDefs = [
      {
        groupId: 'Pagos',
        showAsTable: true,
      }
    ];
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {
        idEmpleado: this.profile.idEmpleado
      } as DescuentoInfonavit,
      minimumItem: {
        idEmpleado: this.profile.idEmpleado
      } as DescuentoInfonavit,
      registerItem: {
        idEmpleado: this.profile.idEmpleado
      } as DescuentoInfonavitReg
    } as ComponentItem;
  }

  getService(): DescuentoInfonavitPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      // Datos generales
      { header: 'No. Desc. INFONAVIT', field: 'id', groupId: 'Descuento INFONAVIT', inAll: true },
      { header: 'Empleado', field: 'nombreEmpleado', groupId: 'Descuento INFONAVIT', inAll: true },
      { header: 'Monto', field: 'monto', inAll: true, groupId: 'Descuento INFONAVIT', formatFunction: this.formatoMoneda },
      { header: 'Mes', field: 'mes', inAll: true, groupId: 'Descuento INFONAVIT', formatFunction: this.formatoMes },
      { header: 'Año', field: 'anio', inAll: true, groupId: 'Descuento INFONAVIT' },
      { header: 'Estatus', field: 'aplicado', inAll: true, groupId: 'Descuento INFONAVIT', formatFunction: this.formatBoolean },
      { header: 'Fecha de registro', field: 'fecRegDescInfonavit', inAll: true, groupId: 'Descuento INFONAVIT', formatFunction: this.formatoFecha },

      // Arreglo de pagos
      { header: 'Fecha de pago', field: 'fecPromPago', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos', formatFunction: this.formatoFecha },
      { header: 'Monto', field: 'montoPromPago', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos', formatFunction: this.formatoMoneda },
      { header: 'Aplicado el', field: 'fecPago', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos', formatFunction: this.formatoFechaPago },
      { header: 'Comentario', field: 'comentario', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos' }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Mis descuentos INFONAVIT";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = [
      "fecIni", "fecFin"
    ];
  }

  formatoFechaPago(value: any) {
    if (!value || value.trim() == "") {
      return "En espera de ser aplicado";
    }
    else {
      let d = Date.parse(value);
      return formatDate(d, "dd 'de' MMMM 'de' yyyy", 'es-MX');
    }
  }

  formatoFecha(value: string) {
    let d = Date.parse(value);
    return formatDate(d, "dd 'de' MMMM 'de' yyyy", 'es-MX');
  }

  formatoMoneda(value: number): string {
    let pipe = new CurrencyPipe('es-MX');
    return pipe.transform(value, 'MXN', '$');
  }

  formatBoolean(value: any) {
    return !value ? 'Pendiente por liquidar' : 'Liquidado';
  }

  formatoMes(value: number): string {
    let pipe = new MesPipePipe();
    return pipe.transform(value);
  }


}




