<!-- {{item | json}} -->

<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('empleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [options]="empleados" [(ngModel)]="item.idEmpleado" optionLabel="nombreCompleto"
                optionValue="id" [filter]="true" filterBy="nombreCompleto" [autoDisplayFirst]="false"></p-dropdown>
            <label for="fl-emp">Colaborador</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('motivo')">
        <br>
        <span class="p-float-label">
            <input id="fl-motivo" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-motivo">Motivo</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('monto')">
        <br>
        <span class="p-float-label">
            <p-inputNumber inputId="fl-monto" [(ngModel)]="item.monto" mode="currency" currency="MXN" locale="es-MX"></p-inputNumber>
            <label for="fl-monto">Monto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecDescuento')">
        <br>
        <span class="p-float-label">
            <p-calendar inputId="fl-fecDesc" [(ngModel)]="item.fecDescuento" [showIcon]="true" dataType="string"
                dateFormat="yy/mm/dd" appendTo="body" [baseZIndex]="20000"></p-calendar>
            <label for="fl-fecDesc">Fecha de descuento</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecInicio')">
        <br>
        <span class="p-float-label">
            <p-calendar inputId="fl-fecIni" [(ngModel)]="item.fecInicio" [showIcon]="true" dataType="string"
                dateFormat="yy/mm/dd" appendTo="body" [baseZIndex]="20000"></p-calendar>
            <label for="fl-fecIni">Fecha inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecFin')">
        <br>
        <span class="p-float-label">
            <p-calendar inputId="fl-fecFin" [(ngModel)]="item.fecFin" [showIcon]="true" dataType="string"
                dateFormat="yy/mm/dd" appendTo="body" [baseZIndex]="20000"></p-calendar>
            <label for="fl-fecFin">Fecha fin</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('estatus')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-estatus" [options]="catEstatus" [(ngModel)]="item.regActivo" optionLabel="label"
                optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
            <label for="fl-estatus">Estatus</label>
        </span>
    </div>
</div>