<p-dialog [(visible)]="showDialog" header="Edición de montos a pagos" [draggable]="false" [resizable]="false"
    [modal]="true" position="center" [closeOnEscape]="true" [closable]="true" [baseZIndex]="10000" [maximizable]="true"
    [style]="{'width':'50vw'}" (onHide)="ocultarDialog()" contentStyleClass="content-dialog"
    [breakpoints]="{'1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '100vw'}">

    <!-- {{item | json}} -->

    <div class="contenedor-progress-spinner" *ngIf="showProgress">
        <p-progressSpinner strokeWidth="8"></p-progressSpinner>
    </div>

    <ng-container *ngIf="!showProgress">
        <div class="p-grid frm-resume">
            <div class="p-col-12">
                <div class="wz-preview-label">Fecha de pago: </div>
                <div class="my-preview-value">{{item.fecPromesaPago | date: "dd 'de' MMMM 'de' yyyy"}}</div>
            </div>

            <div class="p-col-12">
                <div class="wz-preview-label">Monto: </div>
                <div class="my-preview-value">{{item.montoPromesaPago | currency:"$"}}</div>
            </div>

            <div class="p-col-12">
                <div class="wz-preview-label">Comentario: </div>
                <div class="my-preview-value">{{item.comentario}}</div>
            </div>

            <div class="p-col-12">
                <div class="wz-preview-label">Pagado el: </div>
                <div class="my-preview-value">{{item.fechaPago | date: "dd 'de' MMMM 'de' yyyy"}}</div>
            </div>
        </div>
    </ng-container>

    <!-- <ng-template pTemplate="footer">
        <p-button *ngIf="!showProgress" (onClick)="posponerPago()" icon="pi pi-clock" label="Posponer pago"
            tooltipZIndex="20000"
            pTooltip="Modifica la fecha de pago recorriendola hasta despues del ultimo pago programado"
            [disabled]="item.fechaPago ? true : false">
        </p-button> &nbsp;

        <p-button *ngIf="!showProgress" (onClick)="modificarPago()" icon="pi pi-pencil" label="Modificar pago"
            tooltipZIndex="20000"
            pTooltip="Modifica el monto del pago, recalculando el monto de los otros pagos en caso de haber mas pagos pendientes"
            [disabled]="item.fechaPago ? true : false">
        </p-button>
    </ng-template> -->
    <p-toast></p-toast>
</p-dialog>