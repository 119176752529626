import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoPagoRestcService } from 'src/app/client/descuento/tipo-pago-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoPagoPsService extends BaseProcess<TipoPagoRestcService>{

  constructor(httpClient: HttpClient,
    crest: TipoPagoRestcService) { 
      super(httpClient, crest);
    }


}
