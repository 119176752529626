import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'axks-reportes-menu',
  templateUrl: './reportes-menu.component.html',
  styleUrls: ['./reportes-menu.component.scss']
})
export class ReportesMenuComponent extends BaseView implements OnInit {

  @Output() numMenu = new EventEmitter<number>();
  @Output() tituloReportes = new EventEmitter<String>();

  iconoDeuda = faFileInvoiceDollar;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.configTituloReportes();
    }, 50);
  }

  emitirNumReporte(event) {
    this.numMenu.emit(event);
  }

  configTituloReportes() {
    this.tituloReportes.emit("Menú");
  }

}
