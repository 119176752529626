<div class="p-grid frm-part">
    <!-- {{item | json}} -->
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idEmpleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-Emp" [(ngModel)]="item.idEmpleado" [options]="catEmpleados"
                optionLabel="nombreCompleto" [filter]="true" filterBy="nombreCompleto" optionValue="id"
                [autoDisplayFirst]="false" autoWidth="false" appendTo="body" [style]="{width:'90%'}">
                <!-- (onChange)="validarUsuario(item.idEmpleado)"> -->
            </p-dropdown>
            <label for="fl-Emp"><span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">*
                </span>Empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('montoPrestamo')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.montoPrestamo" inputId="currency-us" mode="currency" currency="MXN"
                locale="es-MX" min="1" [style]="{width:'90%'}">
            </p-inputNumber>
            <label for="fl-mnto">
                <span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">* </span>
                Monto total
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="operationMode == op.ADDNEW">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.numPagos" [style]="{width:'90%'}" maxlength="3" max="100" min="1">
            </p-inputNumber>
            <label for="numPago">
                <span *ngIf="operationMode != op.SEARCH" [style]="{'color':'red'}">* </span>
                Número de pagos
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="operationMode == op.ADDNEW">
        <br>
        <span class="p-float-label">
            <input type="text" id="fl-coment" pInputText [(ngModel)]="item.comentario" [pKeyFilter]="alphanumeric"
                [style]="{width:'90%'}">
            <label for="fl-coment">Comentario</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <span *ngIf="operationMode != op.SEARCH" style="color: red; font-size: 0.7rem;">* Campos requeridos</span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == op.ADDNEW">
        <br>
        <br>
        <p-button (onClick)="calcular($event)" [loading]="isCalculate" label="Calcular pagos"
            icon="fa fa-calculator"></p-button>
        <br>
        <br>
        <br>
        <div *ngIf="allowFormFields('montoPromesaPago')" style="margin-top: 20px;">
            <br>
            <div class="p-grid">
                <ng-container *ngFor="let pago of item.pagos">
                    <p-fieldset [legend]="'Pago '+ (item.pagos.indexOf(pago) + 1)"
                        [style]="{'background-color':'#f9f9f9'}">
                        <div class="p-grid">
                            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                <span class="p-float-label">
                                    <p-calendar id="fl-fecIni" dateFormat="yy/mm/dd" [(ngModel)]="pago.fecPromesaPago"
                                        dataType="string" [yearNavigator]="true" [showIcon]="true"
                                        [yearRange]="yearRange" [monthNavigator]="true">
                                    </p-calendar>
                                    <label for="fl-fecIni">
                                        <span>Fecha de pago</span>
                                    </label>
                                </span>
                            </div>
                            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                <span class="p-float-label">
                                    <p-inputNumber [(ngModel)]="pago.montoPromesaPago" inputId="fl-montoPago"
                                        mode="currency" currency="MXN" locale="es-MX" [style]="{'width':'100%'}"
                                        [disabled]="pago.numPago && pago.fecPago ? true : false">
                                    </p-inputNumber>
                                    <label for="fl-montoPago">Monto</label>
                                </span>
                            </div>
                        </div>
                    </p-fieldset>
                    <br>
                    <br>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == op.EDIT && showButtonPagos">
        <p-button label="Configurar pagos" icon="pi pi-external-link" iconPos="right" (onClick)="showPagos = true">
        </p-button>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == op.EDIT">
        <axks-prestamo-pagos *ngIf="operationMode == op.EDIT && showPagos" [showDialog]="showPagos" [item]="item"
            [profile]="profile" [catFechas]="catFechas" (emitShowDialog)="showPagos = $event">
        </axks-prestamo-pagos>
    </div>
</div>

<p-toast></p-toast>