import { Injectable } from '@angular/core';
import { Arest, ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';

import { PagoCalculado, } from 'src/app/api/prestamo/prestamo-calculo-reg';
import { Pago } from '../../api/prestamo/prestamo';

@Injectable({
  providedIn: 'root'
})
export class PrestamosRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msPrestamo + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let restPaths = super.getRestPaths();

    restPaths.detail = "";

    return restPaths;
  }
  calculaValor(calculado): Observable<PagoCalculado> {
    let path = environment.msCalculo;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, calculado, { headers });
  }

  postergarPago(pagoPost: any): Observable<Pago[]> {
    let path = environment.msAplazo;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, pagoPost, { headers }) as Observable<Pago[]>;
  }

  guardarPagoEd(pagoEditado: any): Observable<any> {
    let path = environment.msPrestamo;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.put(path, pagoEditado, { headers }) as Observable<any>;
  }

  guardarPago(pagosEdit: any): Observable<Pago[]> {
    let path = environment.msMonto;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, pagosEdit, { headers }) as Observable<Pago[]>;
  }

  consultarEmpleado(idEmp: any): Observable<any> {
    let path = environment.msConsulta;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, idEmp, { headers }) as Observable<any>;
  }

  consultarPagoid(idpagos: number): Observable<any> {
    let path = environment.msPrestamo + "/" + idpagos;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<any>;
  }
}