import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';


import { AppComponent } from './app.component';

import { ActionsBarModule, DetailModule, OperationsBarModule, ResultListModule, DetailTabModule, BaseMessages, SessionClientProvider, CommonsModule } from '@axks/components';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { PrestamosComponent } from './views/prestamos/prestamos/prestamos.component';
import { PrestamosFormComponent } from './views/prestamos/prestamos-form/prestamos-form.component';
import { AppRoutingModule } from './app-routing.module';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { TipoDescuentoFormComponent } from './views/descuento/tipo-descuento-form/tipo-descuento-form.component';
import { TipoDescuentoComponent } from './views/descuento/tipo-descuento/tipo-descuento.component';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { RestClientModule } from '@axks/net';
import { PrestamosEmpleadoComponent } from './views/prestamos/prestamos-empleado/prestamos-empleado/prestamos-empleado.component';
import { PrestamosEmpleadoFormComponent } from './views/prestamos/prestamos-empleado/prestamos-empleado-form/prestamos-empleado-form.component';
import localeEsMx from '@angular/common/locales/es-MX';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { TreeTableModule } from 'primeng/treetable';
import { PrestamoDetallePagosComponent } from './views/prestamos/prestamo-detalle-pagos/prestamo-detalle-pagos.component';
import { HomeComponent } from './views/home/home.component';
import { DescuentoImssFormComponent } from './views/descuento/descuento-imss/descuento-imss-form/descuento-imss-form.component';
import { DescuentoImssComponent } from './views/descuento/descuento-imss/descuento-imss/descuento-imss.component';
import { DescuentoInfonavitFormComponent } from './views/descuento/descuento-infonavit/descuento-infonavit-form/descuento-infonavit-form.component';
import { DescuentoInfonavitComponent } from './views/descuento/descuento-infonavit/descuento-infonavit/descuento-infonavit.component';
import { DescuentoImssEmpleadoComponent } from './views/descuento/descuento-imss/descuento-imss-empleado/descuento-imss-empleado.component';
import { DescuentoInfonavitEmpleadoComponent } from './views/descuento/descuento-infonavit/descuento-infonavit-empleado/descuento-infonavit-empleado.component';
import { DepositosFormComponent } from './views/depositos/depositos-form/depositos-form.component';
import { DepositosComponent } from './views/depositos/depositos/depositos.component';
import { TiposPagoFormComponent } from './views/pagos/tipos-pago/tipos-pago-form/tipos-pago-form.component';
import { TiposPagoComponent } from './views/pagos/tipos-pago/tipos-pago/tipos-pago.component';
import { PagoFormComponent } from './views/pagos/pago/pago-form/pago-form.component';
import { PagoComponent } from './views/pagos/pago/pago/pago.component';
import { TiposPagoCatComponent } from './views/pagos/tipos-pago/tipos-pago-cat/tipos-pago-cat.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { ReportesComponent } from './views/reportes/reportes/reportes.component';
import { ReportesMenuComponent } from './views/reportes/reportes-menu/reportes-menu.component';
import { ReportesDeudasComponent } from './views/reportes/reportes-deudas/reportes-deudas.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DescuentoImssPagosComponent } from './views/descuento/descuento-imss/descuento-imss-pagos/descuento-imss-pagos.component';
import { FieldsetModule } from 'primeng/fieldset';
import { MesPipePipe } from './pipe/mes-pipe.pipe';
import { DescuentoInfonavitPagosComponent } from './views/descuento/descuento-infonavit/descuento-infonavit-pagos/descuento-infonavit-pagos.component';
import { PrestamoPagosComponent } from './views/prestamos/prestamo-pagos/prestamo-pagos.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OtrosDescuentosFormComponent } from './views/descuento/otros-descuentos/otros-descuentos-form/otros-descuentos-form.component';
import { OtrosDescuentosComponent } from './views/descuento/otros-descuentos/otros-descuentos/otros-descuentos.component';

registerLocaleData(localeEsMx);

@NgModule({
  declarations: [
    AppComponent,
    PrestamosComponent,
    PrestamosFormComponent,
    TipoDescuentoFormComponent,
    TipoDescuentoComponent,
    PrestamosEmpleadoComponent,
    PrestamosEmpleadoFormComponent,
    PrestamoDetallePagosComponent,
    HomeComponent,
    DescuentoImssComponent,
    DescuentoImssFormComponent,
    DescuentoInfonavitComponent,
    DescuentoInfonavitFormComponent,
    DescuentoImssEmpleadoComponent,
    DescuentoInfonavitEmpleadoComponent,
    DepositosFormComponent,
    DepositosComponent,
    TiposPagoFormComponent,
    TiposPagoComponent,
    PagoFormComponent,
    PagoComponent,
    TiposPagoCatComponent,
    ReportesComponent,
    ReportesMenuComponent,
    ReportesDeudasComponent,
    DescuentoImssPagosComponent,
    MesPipePipe,
    DescuentoInfonavitPagosComponent,
    PrestamoPagosComponent,
    OtrosDescuentosFormComponent,
    OtrosDescuentosComponent
  ],
  imports: [
    FontAwesomeModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,
    TableModule,
    ToolbarModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    PanelModule,
    InputNumberModule,
    CalendarModule,
    BrowserModule,
    RestClientModule,
    CommonsModule,
    ButtonModule,
    CheckboxModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ProgressBarModule,
    TreeTableModule,
    BrowserModule,
    ToggleButtonModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    TooltipModule,
    OverlayPanelModule,
    FieldsetModule
  ],
  providers: [MessageService, BaseMessages, SessionClientProvider,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: 'env', useValue: environment },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
