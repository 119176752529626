import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { MessageService } from 'primeng/api';
import { DescuentoPagosVariosRestcService } from 'src/app/client/descuento/descuento-pagos-varios-restc.service';

@Injectable({
  providedIn: 'root'
})
export class DescuentoPagosVariosPsService extends BaseProcess<DescuentoPagosVariosRestcService>{

  constructor(httpClient: HttpClient,
    crest: DescuentoPagosVariosRestcService,
    private messages: MessageService) { 
      super(httpClient,crest);
    }


}
