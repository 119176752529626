import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateToolsRestcService {

  constructor(private httpClient: HttpClient) { }

  fechasPago(idEmpleado: number): Observable<string[]> {
    let path = environment.msFechasDrop + "/" + idEmpleado;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<string[]>;
  }
}
