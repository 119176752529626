import { formatCurrency, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Depositos, DepositosReg } from 'src/app/api/depositos/deposito';
import { DepositosPsService } from 'src/app/procs/depositos/depositos-ps.service';

@Component({
  selector: 'axks-depositos',
  templateUrl: './depositos.component.html',
  styleUrls: ['./depositos.component.scss']
})
export class DepositosComponent extends BaseMainComponent<DepositosPsService> implements OnInit {

  enableEdit: boolean = false;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private client: DepositosPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        conciliable: true
      } as Depositos,
      minimumItem: {
        conciliable: true
      } as Depositos,
      registerItem: {
        conciliable: true
      } as DepositosReg
    } as ComponentItem;
    return empty;
  }
  getService(): DepositosPsService {
    return this.client;
  }
  getColListDefinition(): ColumnDefinition[] {
    let columns = [
      { field: 'id', header: 'Clave', inAll: true },
      { field: 'concepto', header: 'Concepto', inAll: true },
      { field: 'monto', header: 'Monto', inAll: true, formatFunction: this.formatoMoneda },
      { field: 'fechaDeposito', header: 'Fecha de deposito', inAll: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return columns;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = [];
    this.formSearch = [];
  }

  getComponentTitle(): string {
    return 'Depositos';
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  formatoFecha(date: string): string {
    let datePipe = new DatePipe('es-MX');
    return datePipe.transform(date, "dd 'de' MMMM 'de' yyyy", 'America/Mexico_City');
  }

  rowSelect(event: any): void {
    let item = event as Depositos;
    if (!item.conciliable || (item.conciliable && !item.conciliado)) {
      this.enableEdit = true;
    }
    else {
      this.enableEdit = false;
    }
    super.rowSelect(event);
  }

}
