import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoPago } from '../../../../api/descuento/tipo-pago';

@Component({
  selector: 'axks-tipos-pago-form',
  templateUrl: './tipos-pago-form.component.html',
  styleUrls: ['./tipos-pago-form.component.scss']
})
export class TiposPagoFormComponent extends BaseView implements OnInit {

  @Input() item: TipoPago = {};

  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvider: SessionClientProvider) {

    super(screenModeService, deviceService, messageService, sessionProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
  }

}