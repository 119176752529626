import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, OperationScreenModeService, SessionClientProvider, ComponentItem, ColumnDefinition, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Descuento, DescuentoReg } from 'src/app/api/descuento/descuento';
import { DescuentoPsService } from 'src/app/procs/descuento/descuento-ps.service';
import { TipoDescuentoEnum } from 'src/app/api/enums/tipo-descuento-enum.enum';
import { DatePipe, formatCurrency } from '@angular/common';

@Component({
  selector: 'axks-otros-descuentos',
  templateUrl: './otros-descuentos.component.html',
  styleUrls: ['./otros-descuentos.component.scss']
})
export class OtrosDescuentosComponent extends BaseMainComponent<DescuentoPsService> implements OnInit {

  OP_MODE = OperationMode;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private descRestc: DescuentoPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idTipoDescuento: {
          idTipoDescuento: TipoDescuentoEnum.OTRO
        },
      } as Descuento,
      minimumItem: {
        idTipoDescuento: {
          idTipoDescuento: TipoDescuentoEnum.OTRO
        },
      } as Descuento,
      registerItem: {
        idTipoDescuento: {
          idTipoDescuento: TipoDescuentoEnum.OTRO
        },
        idEmpleadoUser: this.profile.idEmpleado
      } as DescuentoReg
    } as ComponentItem;
    return empty;
  }

  getService(): DescuentoPsService {
    return this.descRestc;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { field: 'id', header: 'Núm. Registro', inAll: true },
      { field: 'idEmpleado', header: 'Núm. Empleado', inDetail: true },
      { field: 'empleado', header: 'Empleado', inAll: true },
      { field: 'comentario', header: 'Motivo', inDetail: true },
      { field: 'idTipoDescuento.tipoDescuento', header: 'Tipo de descuento', inAll: true },
      { field: 'monto', header: 'Monto', inAll: true, formatFunction: this.formatoMoneda },
      { field: 'fecDescuento', header: 'Fecha de descuento', inAll: true, formatFunction: this.formatoFecha },
      { field: 'regActivo', header: 'Estatus', inAll: true, formatFunction: this.formatoBoolean }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Otros descuentos";
  }

  formatoFecha(value: any): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy");
  }

  formatoMoneda(value: any): string {
    return formatCurrency(value, 'es-MX', '$');
  }

  formatoBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Descuento por aplicar' : 'Descuento aplicado');
    }
    else {
      return '';
    }
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = ["empleado", "motivo", "monto", "fecDescuento"];
    this.formSearch = ["empleado", "fecInicio", "fecFin", "estatus"];
  }

  save(event: any): void {
    this.messageService.clear();
    if (this.operationMode == this.OP_MODE.EDIT) {
      if (this.workItem().regActivo == false) {
        this.infoMessage("Descuento aplicado", "El descuento no se puede editar porque ya ha sido aplicado");
      }
      else {
        super.save(event);
      }
    }
    else {
      super.save(event);
    }
  }
}
