<div class="p-grid">
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(1)">
            <div class="div-center">
                <fa-icon [icon]="iconoDeuda" style="font-size: 5rem;"></fa-icon>
                <span><br>Saldos deudores</span>
            </div>
        </p-card>
    </div>
    <!-- <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 p-col-6">
        <p-card class="card-reporte" (click)="emitirNumReporte(2)">
            <div class="div-center">
                <i class="pi pi-file" style="font-size: 5rem;"></i>
                <span><br>Contratos</span>
            </div>
        </p-card>
    </div> -->
</div>