import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { TipoPagoPsService } from 'src/app/procs/descuento/tipo-pago-ps.service';
import { TipoPago } from '../../../../api/descuento/tipo-pago';

@Component({
  selector: 'axks-tipos-pago-cat',
  templateUrl: './tipos-pago-cat.component.html',
  styleUrls: ['./tipos-pago-cat.component.scss']
})
export class TiposPagoCatComponent implements OnInit {

  @Input() value?: TipoPago;
  @Input() addNewEnable: boolean;
  @Input() operationMode: OperationMode;

  @Output() onChange = new EventEmitter<number>();

  catalog: TipoPago[];
  virtualScroll: boolean = false;
  showForm: boolean = false;
  newItem: TipoPago;

  constructor(private client: TipoPagoPsService, private messages: MessageService) {
  }

  ngOnInit(): void {
    if (this.operationMode) {
      this.enableAddNewButton();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    if (this.operationMode) {
      this.enableAddNewButton();
    }
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as TipoPago;

    this.virtualScroll = false;
    this.client.find(filter).subscribe(
      (data) => {
        this.catalog = data;
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {
          let message = error.error as Message;
          console.debug("Message: ");
          console.debug(message)
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron modelos en está área. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de modelos", detail: messText });
  }

  selectOption(event, value) {
    this.onChange.emit(value.id);
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = {
      marca: {
        tipoEquipo: {}
      }
    } as TipoPago;
  }

  onSaveClick(event) {
    this.client.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = {};
      }, (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = {};
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = {};
  }

}
