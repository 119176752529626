import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { Aplazo } from 'src/app/api/descuento/aplazo';
import { DescuentoInfonavit } from 'src/app/api/descuento/descuento-infonavit';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DescuentoInfonavitRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msInfonavit + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let restPaths = super.getRestPaths();

    restPaths.detail = "";

    return restPaths;
  }
  aplazar(aplazar: any): Observable<Aplazo> {
    let path = environment.msImssAplaza;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, aplazar, { headers }) as Observable<Aplazo>;
  }
  consultarEmpleado(idEmp:any): Observable<any>{
    let path = environment.msBusquedaInfonavit;

    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.post(path,idEmp, { headers }) as Observable<any>;
  }
  consultarInfonavit(id:any): Observable<any>{
    let path = environment.msInfonavit+"/"+id;

    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.get(path, { headers }) as Observable<any>;
  }consultarFecha(id: any): Observable<any> {
    let path = environment.msFechasDrop + "/" + id;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<any>;
  }

  guardarPago(pagosEdit: DescuentoInfonavit): Observable<DescuentoInfonavit> {
    let path = environment.msInfonavitPagos;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");


    return this.httpClient.post(path,pagosEdit, { headers }) as Observable<DescuentoInfonavit>;

  }
  guardarPagoForm(pagosEdit: DescuentoInfonavit): Observable<DescuentoInfonavit> {
    let path = environment.msInfonavit;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");


    return this.httpClient.post(path,pagosEdit, { headers }) as Observable<DescuentoInfonavit>;

  }
}
