import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PrestamosRestcService } from 'src/app/client/prestamos/prestamos-restc.service';
import { Pago } from 'src/app/api/prestamo/prestamo';

@Component({
  selector: 'axks-prestamo-detalle-pagos',
  templateUrl: './prestamo-detalle-pagos.component.html',
  styleUrls: ['./prestamo-detalle-pagos.component.scss']
})
export class PrestamoDetallePagosComponent extends BaseView implements OnInit {

  @Input() item: Pago;
  @Input() showDialog: boolean;
  @Input() profile: ProfileC;

  @Output() onHide = new EventEmitter<boolean>();
  @Output() onReloadDetail = new EventEmitter();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private postr: PrestamosRestcService,
    private prestamoPs: PrestamosRestcService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
  }

  posponerPago() {
    this.showProgress = true;

    let request = {
      idPrestamo: this.item.idPrestamo,
      numPago: this.item.numPago,
      montoPromesaPago: this.item.montoPromesaPago,
      fecPromesaPago: this.item.fecPromesaPago,
      idEmpleadoUser: this.profile.idEmpleado,
    } as any;

    this.postr.postergarPago(request).subscribe(
      (data) => {
        this.showProgress = false;
        this.successMessage("Pago aplazado", "El pago se aplazó correctamente");
        this.recargarDetalle();
        this.ocultarDialog();
      },
      (error) => {
        this.errorMessage("Error", "No se pudo posponer el pago");
        this.showProgress = false;
      }
    );
  }

  modificarPago() {
    let request = {
      idPrestamo: this.item.idPrestamo,
      numPago: this.item.numPago,
      montoPromesaPago: this.item.montoPromesaPago,
      fecPromesaPago: this.item.fecPromesaPago,
      idEmpleadoUser: this.profile.idEmpleado,
    } as any;

    this.prestamoPs.guardarPago(request).subscribe(
      (data) => {
        this.showProgress = false;
        this.successMessage("Pago modificado", "El pago se ha modificado correctamente");
        this.recargarDetalle();
        this.ocultarDialog();
      },
      (error) => {
        this.errorMessage("Error", "No se pudo modificar el pago");
        this.showProgress = false;
      }
    );
  }

  ocultarDialog() {
    this.onHide.emit(false);
  }

  recargarDetalle() {
    this.onReloadDetail.emit();
  }
}

