<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idTipoPago')">
        <!--<br>
         <span class="p-float-label">
            <p-dropdown inputId="fl-tpPg" [(ngModel)]="item.idTipoPago.id" [options]="idTipoPagos"
                optionLabel="tipoPago" [filter]="true" filterBy="tipoPago" optionValue="id" [autoDisplayFirst]="false"
                autoWidth="false" appendTo="body">
            </p-dropdown>
            <label for="fl-tpPg">Tipo de Pago</label>
        </span> -->
        <axks-tipos-pago-cat [(value)]="item.idTipoPago" [operationMode]="operationMode"></axks-tipos-pago-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('concepto')">
        <br>
        <span class="p-float-label">
            <input id="fl-con" type="text" pInputText [(ngModel)]="item.concepto">
            <label for="fl-con">Concepto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('monto')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.monto" inputId="fl-monto" mode="currency" currency="MXN" locale="es-MX">
            </p-inputNumber>
            <label for="fl-monto">Monto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fecPago')">
        <br>
        <span class="p-float-label">
            <p-calendar inputId="fl-fecPag" [(ngModel)]="item.fecPago" [showIcon]="true" [maxDate]="fechaMax"
                dateFormat="yy/mm/dd" dataType="string" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
            <label for="fl-fecPag">Fecha de pago</label>
        </span>
    </div>

</div>