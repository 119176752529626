import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoPago, TipoPagoReg } from 'src/app/api/descuento/tipo-pago';
import { TipoPagoRestcService } from 'src/app/client/descuento/tipo-pago-restc.service';
import { TipoPagoPsService } from 'src/app/procs/descuento/tipo-pago-ps.service';

@Component({
  selector: 'axks-tipos-pago',
  templateUrl: './tipos-pago.component.html',
  styleUrls: ['./tipos-pago.component.scss']
})
export class TiposPagoComponent extends BaseMainComponent<TipoPagoPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvider: SessionClientProvider,
    private psDelete: TipoPagoRestcService,
    private psService: TipoPagoPsService) {

    super(deviceService, messageService, screenModeService, sessionProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        regActivo: true
      } as TipoPago,
      minimumItem: {
        regActivo: true
      } as TipoPago,
      registerItem: {} as TipoPagoReg
    } as ComponentItem;
    return empty;
  }

  getService(): TipoPagoPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'Id ', field: 'id', inDetail: true, inResultList: true },
      { header: 'Tipo Pago', field: 'tipoPago', inDetail: true, inResultList: true },

    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Tipos de pago";
  }

  delete(event) {
    let prof = this.getProfile() as ProfileC;
    idUserEmp: prof.idEmpleado
    this.item.item.idUserEmp = prof.idEmpleado
    this.psDelete.eliminarRegistro(this.item.item.id, this.item.item.idUserEmp).subscribe(
      (data) => {
        this.resultList = data;
        this.messageService.add({ severity: 'success', summary: 'Se eliminò correctamente ', detail: '' });
        setTimeout(function () { location.reload(); }, 1000);
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Este  dato ya ha sido eliminado ', detail: '' });
      }
    );
  }

}