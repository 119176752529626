<p-dialog header="Programación de pagos" [(visible)]="showDialog" [modal]="true" (onHide)="ocultarDialog()"
    [breakpoints]="{'1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '100vw'}" [style]="{'width': '60vw'}"
    [contentStyle]="{'padding': '1rem', 'background-color':'#f9f9f9'}" [maximizable]="true" [resizable]="false">

    <ng-container *ngFor="let pago of item.pagos">
        <p-fieldset [legend]="'Pago '+ (item.pagos.indexOf(pago) + 1)" [style]="{'background-color':'#f9f9f9'}">
            <div class="p-grid">
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                    <span class="p-float-label">
                        <p-dropdown inputId="fl-fecPago" [(ngModel)]="pago.fecPromPago" [options]="catFechas"
                            [filter]="true" filterBy="valor" [autoDisplayFirst]="false" autoWidth="false"
                            appendTo="body" [style]="{'width':'100%'}" [disabled]="pago.numPago && pago.fecPago ? true : false">
                        </p-dropdown>
                        <label for="fl-fecPago">Fecha de pago</label>
                    </span>
                </div>
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                    <span class="p-float-label">
                        <p-inputNumber [(ngModel)]="pago.montoPromPago" inputId="fl-montoPago" mode="currency"
                            currency="MXN" locale="es-MX" [style]="{'width':'100%'}" [disabled]="pago.numPago && pago.fecPago ? true : false">
                        </p-inputNumber>
                        <label for="fl-montoPago">Monto</label>
                    </span>
                </div>
                <div
                    class="p-sm-offset-10 p-md-offset-10 p-lg-offset-10 p-lg-offset-10 p-xl-offset-10 p-offset-10 p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-2">
                    <p-button type="button" (onClick)="quitar(pago)" icon="pi pi-trash" pTooltip="Eliminar pago"
                        styleClass="p-button-danger" [disabled]="pago.numPago && pago.fecPago ? true : false">
                    </p-button>
                </div>
            </div>
        </p-fieldset>
        <br>
        <br>
    </ng-container>

    <ng-template pTemplate="footer">
        <p-button [style]="{'width':'35px'}" (onClick)="insertar()" pTooltip="Insertar nuevo pago" icon="pi pi-plus">
        </p-button>
        &nbsp;
        <p-button (onClick)="ocultarDialog()" icon="pi pi-save" label="Guardar pagos">
        </p-button>
    </ng-template>
</p-dialog>