import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { environment } from 'src/environments/environment';
import { TipoPago } from '../../api/descuento/tipo-pago';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoPagoRestcService extends ARestClient {


  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msTipoPago + "/";
  }
  processBody(res: any) {
    let body = res;
    return body || {};
  }


  getRestPaths(): RestBasicPaths {
    let restPaths = super.getRestPaths();

    restPaths.detail = "";

    return restPaths;
  }

  /*  removeElement<TipoPago, Any>(item: TipoPago): Observable<any>{
     let path = this.getBaseEndpoint()+this.getRestPaths().remove;
 
     if(!path.endsWith("/")){
       path += "/";
     }
 
     const headers = new HttpHeaders()
       .set('Access-Control-Allow-Origin', "*")
       .set('Access-Control-Allow-Headers', "*");
 
 
     return this.httpClient.delete(path,item, { headers }) as Observable<any>;
 
 
   } */

  eliminarRegistro(id: any, idUserEmp: any): Observable<any> {
    let path = environment.msTipoPago + "/" + id + "&" + idUserEmp;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.delete(path, { headers }) as Observable<any>;
  }
}