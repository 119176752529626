import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DescuentoPagosVariosRestcService } from 'src/app/client/descuento/descuento-pagos-varios-restc.service';
import { DescuentoPagosVariosPsService } from 'src/app/procs/descuento/descuento-pagos-varios-ps.service';
import { PagosVarios, PagosVariosReg } from '../../../../api/descuento/descuento-pagos-varios';

@Component({
  selector: 'axks-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent extends BaseMainComponent<DescuentoPagosVariosPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected screenModeService: OperationScreenModeService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private pagosVar: DescuentoPagosVariosPsService,
    private psDelete: DescuentoPagosVariosRestcService) {
    super(deviceService, messageService, screenModeService, sessProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idTipoPago: {}
      } as PagosVarios,
      registerItem: {
        idTipoPago: {},
        idEmpresa: this.profile.empresa
      } as PagosVariosReg,
      minimumItem: {
        idTipoPago: {}
      } as PagosVarios
    } as ComponentItem;

    return empty;
  }

  getService(): DescuentoPagosVariosPsService {
    return this.pagosVar;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'No. pago', field: 'id', inDetail: true, inResultList: true },
      { header: 'Concepto', field: 'concepto', inAll: true },
      { header: 'Monto', field: 'monto', inAll: true, mode: "currency", currency: "MXN", formatFunction: this.formatMonto },
      { header: 'Tipo de pago', field: 'idTipoPago.tipoPago', inAll: true },
      { header: 'Fecha de pago', field: 'fecPago', inAll: true, formatFunction: this.formatDate }

    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem(): number {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Pagos";
  }

  formatDate(value: string): string {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy");
  }

  formatMonto(monto): string {
    let pipe = new CurrencyPipe("es-MX", "MXN");
    return pipe.transform(monto);
  }

  delete(event) {
    let prof = this.getProfile() as ProfileC;
    idUserEmp: prof.idEmpleado
    this.item.item.idUserEmp = prof.idEmpleado;
    console.log()
    this.psDelete.eliminarRegistro(this.item.item.id, this.item.item.idUserEmp).subscribe(
      (data) => {
        this.resultList = data;
        this.messageService.add({ severity: 'success', summary: 'Se eliminò correctamente ', detail: '' });
        setTimeout(function () { location.reload(); }, 1000);
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Este  dato ya ha sido eliminado ', detail: '' });
      }
    );
  }

}