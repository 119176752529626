import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Empleado } from 'src/app/api/empleado';
import { environment } from 'src/environments/environment';
import { TipoDescuento } from '../../api/descuento/descuento';

@Injectable({
  providedIn: 'root'
})
export class TipoDescuentoCatRestcService {

  constructor(private httpClient: HttpClient) { }

  catTipoDescuento(): Observable<TipoDescuento> {
    let path = environment.msCatTipoDEsc;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }

}
