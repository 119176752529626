import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Descuento } from 'src/app/api/descuento/descuento';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';

@Component({
  selector: 'axks-otros-descuentos-form',
  templateUrl: './otros-descuentos-form.component.html',
  styleUrls: ['./otros-descuentos-form.component.scss']
})
export class OtrosDescuentosFormComponent extends BaseView implements OnInit {

  @Input() item: Descuento;

  empleados: EmpleadoNombre[];
  catEstatus: any[];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private empRestc: EmpleadoRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idTipoDescuento: {}
      }
    }

    this.initCatEmpleados();
    this.initCatEstatus();
  }

  initCatEstatus() {
    this.catEstatus = [
      { value: "true", label: 'Descuento por aplicar' },
      { value: "false", label: 'Descuento aplicado' }
    ];
  }

  initCatEmpleados() {
    this.messageService.clear();
    this.empRestc.getEmpleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.empleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo consultar la lista de empleados");
      }
    );
  }
}
