import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DescuentoImss, DescuentoImssReg, Pago } from 'src/app/api/descuento/descuento-imss';
import { DescuentoImssPsService } from '../../../../procs/descuento/descuento-imss-ps.service';
import { formatDate, CurrencyPipe } from '@angular/common';
import { MesPipePipe } from '../../../../pipe/mes-pipe.pipe';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { EmpleadoNombre } from 'src/app/api/empleado';

@Component({
  selector: 'axks-descuento-imss',
  templateUrl: './descuento-imss.component.html',
  styleUrls: ['./descuento-imss.component.scss']
})
export class DescuentoImssComponent extends BaseMainComponent<DescuentoImssPsService> implements OnInit {

  tabDefs: TabDefinition[];
  catEmpleados: EmpleadoNombre[];
  showButtonPagos: boolean;
  OP_MODE = OperationMode;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: DescuentoImssPsService,
    private clientEmp: EmpleadoRestcService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initTabsMenu();
    this.initCatEmpleados();
  }

  initCatEmpleados() {
    this.clientEmp.getEmpleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener la lista de empleados");
      }
    );
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idUsrEmpleado: this.profile.idEmpleado,
        pagos: [{}] as Pago
      } as DescuentoImss,
      minimumItem: {
        idUsrEmpleado: this.profile.idEmpleado
      } as DescuentoImss,
      registerItem: {
        idUsrEmpleado: this.profile.idEmpleado,
        pagos: [{}] as Pago
      } as DescuentoImssReg
    } as ComponentItem;
    return empty;
  }

  getService(): DescuentoImssPsService {
    return this.psService;
  }

  addNew(event: any): void {
    this.showButtonPagos = false;
    super.addNew(event);
  }

  clean(event: any): void {
    this.showButtonPagos = false;
    this.workItem().fecIni = null;
    this.workItem().fecFin = null;
    super.clean(event);
  }

  cancel(event: any): void {
    this.showButtonPagos = false;
    super.cancel(event);
  }

  catchShowButtonPagos(event) {
    this.showButtonPagos = event;
  }

  initTabsMenu() {
    this.tabDefs = [
      {
        groupId: "Pagos",
        showAsTable: true
      }
    ] as TabDefinition[];
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      // Datos generales
      { header: 'No. Desc. IMSS', field: 'id', inAll: true, groupId: 'Descuento IMSS' },
      { header: 'Empleado', field: 'nombreEmpleado', inAll: true, groupId: 'Descuento IMSS' },
      { header: 'Monto', field: 'monto', inAll: true, groupId: 'Descuento IMSS', formatFunction: this.formatoMoneda },
      { header: 'Mes', field: 'mes', inAll: true, groupId: 'Descuento IMSS', formatFunction: this.formatoMes },
      { header: 'Año', field: 'anio', inAll: true, groupId: 'Descuento IMSS' },
      { header: 'Estatus', field: 'aplicado', inAll: true, groupId: 'Descuento IMSS', formatFunction: this.formatBoolean },
      { header: 'Fecha de registro', field: 'fecRegDescImss', inAll: true, groupId: 'Descuento IMSS', formatFunction: this.formatoFecha },

      // Arreglo de pagos
      { header: 'Fecha de pago', field: 'fecPromPago', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos', formatFunction: this.formatoFecha },
      { header: 'Monto', field: 'montoPromPago', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos', formatFunction: this.formatoMoneda },
      { header: 'Aplicado el', field: 'fecPago', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos', formatFunction: this.formatoFechaPago },
      { header: 'Comentario', field: 'comentario', inDetail: true, groupId: 'Pagos', arrayFieldName: 'pagos' }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Descuentos IMSS";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = [
      "idEmpleado", "fecIni", "fecFin"
    ];
    this.formFields = [
      "idEmpleado", "periodo", "monto", "numPagos", "comentario", "pagos"
    ];
  }

  formatoFechaPago(value: any) {
    if (!value || value.trim() == "") {
      return "En espera de ser aplicado";
    }
    else {
      let d = Date.parse(value);
      return formatDate(d, "dd 'de' MMMM 'de' yyyy", 'es-MX');
    }
  }

  formatoFecha(value: string) {
    let d = Date.parse(value);
    return formatDate(d, "dd 'de' MMMM 'de' yyyy", 'es-MX');
  }

  formatoMoneda(value: number): string {
    let pipe = new CurrencyPipe('es-MX');
    return pipe.transform(value, 'MXN', '$');
  }

  formatBoolean(value: any) {
    return !value ? 'Pendiente por liquidar' : 'Liquidado';
  }

  formatoMes(value: number): string {
    let pipe = new MesPipePipe();
    return pipe.transform(value);
  }

  save(event) {
    if (this.workItem().anio && this.workItem().mes &&
      this.workItem().idEmpleado && this.workItem().idUsrEmpleado &&
      this.workItem().monto && this.workItem().monto > 0 &&
      this.workItem().pagos.length > 0
    ) {
      let acumulado = 0;
      let informacionFaltante = false;
      for (let pago of this.workItem().pagos) {
        if (pago.fecPromPago && pago.montoPromPago && pago.montoPromPago > 0) {
          acumulado += pago.montoPromPago
        }
        else {
          informacionFaltante = true;
        }
      }

      if (informacionFaltante == false) {
        acumulado = parseFloat(acumulado.toFixed(2));

        if (acumulado == this.workItem().monto) {
          if (this.operationMode == this.OP_MODE.EDIT && this.workItem().aplicado == true) {
            this.infoMessage("Edicion no disponible", "El descuento ya fue aplicado por completo y no puede ser editado");
          }
          else {
            super.save(event);
          }
        } else {
          this.warningMessage("Montos sin cuadrar", "Los montos de los pagos no coincide con la cantidad total del descuento, favor de revisarlo");
        }
      }
      else {
        this.warningMessage("Información faltante", "Favor de llenar todos los campos requeridos");
      }
    }
    else {
      this.warningMessage("Información faltante", "Favor de llenar todos los campos requeridos");
    }
  }

}
