<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar" [addNewButton]="false" searchLabel="Buscar"
        [searchButton]="true" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <!-- {{item | json}} -->
    <axks-detail-tab *ngIf="showDetail()" class="axks-detail-tab-80" [data]="workItem()" [colsDefinition]="colDefinition"
        [detailFields]="detailFields" [tabDefs]="tabDefs">
    </axks-detail-tab>

    <axks-prestamos-empleado-form #cmpForm *ngIf="showForm()" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-'+operationMode">
    </axks-prestamos-empleado-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)"
        (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)" saveLabel="Guardar" cancelLabel="Cancelar"
        cleanLabel="Limpiar" [printButton]="false" [csvFileButton]="false" [pdfFileButton]="false" [editButton]="false"
        [saveButton]="true" [txtFileButton]="false" [deleteButton]="false">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>