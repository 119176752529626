import { version } from '../../package.json';
export const environment = {
  version: version,
  production: false,
  dev: false,
  uat: true,

  appId: "finanzas-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  },

  msPrestamo: 'https://nomina.uat-ms.axkans.org/nomina/prestamo',
  msEmpleado: 'https://rh.uat-ms.axkans.org/rh/empleado/all',
  msTipoDesc: 'https://nomina.uat-ms.axkans.org/nomina/tipo-descuento',
  msDescuento: 'https://nomina.uat-ms.axkans.org/nomina/descuento',
  msPersona: 'https://pers.uat-ms.axkans.org/personas/person',
  msCatTipoDEsc: 'https://nomina.uat-ms.axkans.org/nomina/tipo-descuento/all',
  msCalculo: 'https://nomina.uat-ms.axkans.org/nomina/prestamo/calculo-prestamo',
  msAplazo: 'https://nomina.uat-ms.axkans.org/nomina/prestamo/aplazar-pago',
  msMonto: "https://nomina.uat-ms.axkans.org/nomina/prestamo/modificar-monto",
  msConsulta: "https://nomina.uat-ms.axkans.org/nomina/prestamo/search",
  msBusquedaImss: "https://nomina.uat-ms.axkans.org/nomina/descuento-imss/search",
  msBusquedaInfonavit: "https://nomina.uat-ms.axkans.org/nomina/descuento-infonavit/search",
  msImss: "https://nomina.uat-ms.axkans.org/nomina/descuento-imss",
  msInfonavit: "https://nomina.uat-ms.axkans.org/nomina/descuento-infonavit",
  msImssAplaza: "https://nomina.uat-ms.axkans.org/nomina/descuento-imss/aplazar",
  msInfonavitAplaza: "https://nomina.uat-ms.axkans.org/nomina/descuento-infonavit/aplazar",
  msFechasDrop: "https://nomina.uat-ms.axkans.org/nomina/date-tools/fechas-de-pago",
  msImssPagos: "https://nomina.uat-ms.axkans.org/nomina/descuento-imss/modificar",
  msInfonavitPagos: "https://nomina.uat-ms.axkans.org/nomina/descuento-infonavit/modificar",
  msTipoPago: "https://nomina.uat-ms.axkans.org/nomina/tipo-pago",
  msPagosVarios: "https://nomina.uat-ms.axkans.org/nomina/pagos-varios",
  msCatTipoPago: "https://nomina.uat-ms.axkans.org/nomina/tipo-pago/search",
  msDepositos: "https://nomina.uat-ms.axkans.org/nomina/depositos",
  msEmpleadoPs: "https://rh.uat-ms.axkans.org/rh/empleado/ps",
  msReportes: "https://nomina.uat-ms.axkans.org/nomina/reportes"
};
