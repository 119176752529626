import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, SessionClientProvider, OperationScreenModeService, ColumnDefinition, ComponentItem } from '@axks/components';
import { MessageService } from 'primeng/api';
import { TipoDescuentoPsService } from '../../../procs/descuento/tipo-descuento-ps.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TipoDescuento, TipoDescuentoReg } from '../../../api/descuento/descuento';

@Component({
  selector: 'axks-tipo-descuento',
  templateUrl: './tipo-descuento.component.html',
  styleUrls: ['./tipo-descuento.component.scss']
})
export class TipoDescuentoComponent extends BaseMainComponent<TipoDescuentoPsService> implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: TipoDescuentoPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as TipoDescuento,
      minimumItem: {} as TipoDescuento,
      registerItem: {} as TipoDescuentoReg
    } as ComponentItem;
    return empty;
  }

  getService(): TipoDescuentoPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'ID ', field: 'idTipoDescuento', inAll: true },
      { header: 'Tipo de descuento', field: 'tipoDescuento', inAll: true  },
    ] as ColumnDefinition[];
    return colsDef;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.idTipoDescuento || this.item.item.idTipoDescuento;
  }
  getComponentTitle(): string {
    return "Tipo de permiso";
  }

}
