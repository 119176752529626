<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <span class="p-float-label">
            <p-inputNumber inputId="inputnumber" [(ngModel)]="item.montoPrestamo" mode="currency" currency="MXN" locale="es-MX"></p-inputNumber>
            <label for="inputnumber">Monto prestado</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <span class="p-float-label">
            <p-calendar inputId="fl-fecFin" [(ngModel)]="item.fechaSolicitud" dateFormat="yy/mm/dd"
                [yearNavigator]="true" [showIcon]="true" [yearRange]="yearRange" [monthNavigator]="true"
                dataType="string">
            </p-calendar>
            <label for="fl-fecFin">Fecha solicitud</label>
        </span>
    </div>
</div>