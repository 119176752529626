import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DescuentoPagosVariosRestcService } from 'src/app/client/descuento/descuento-pagos-varios-restc.service';
import { PagosVarios } from '../../../../api/descuento/descuento-pagos-varios';
import { TipoPago } from '../../../../api/descuento/tipo-pago';

@Component({
  selector: 'axks-pago-form',
  templateUrl: './pago-form.component.html',
  styleUrls: ['./pago-form.component.scss']
})
export class PagoFormComponent extends BaseView implements OnInit {

  @Input() item: PagosVarios = {};
  idTipoPagos?: TipoPago[] = [];
  fechaMax: Date;
  yearRange: string;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessProvider: SessionClientProvider,
    private catRest: DescuentoPagosVariosRestcService) {
    super(screenModeService, deviceService, messageService, sessProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idTipoPago: {} as TipoPago
      } as PagosVarios;
    }

    this.initCatalogos();

    this.fechaMax = new Date();
    this.yearRange = (new Date().getFullYear() - 1) + ':' + (new Date().getFullYear());
  }

  initCatalogos() {
    let filtro = {
      regActivo: true
    } as TipoPago

    this.catRest.catTipoPago(filtro).subscribe(
      (data) => {
        this.idTipoPagos = data as TipoPago[];
      },
      (error) => {
        this.errorMessage("Error de busqueda.", "No se pudo obtener los tipos de pagos.");
      }
    );
  }
}
