import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DescuentoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msDescuento + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let restPaths = super.getRestPaths();

    restPaths.detail = "";
    restPaths.remove = "remove";

    return restPaths;
  }
  
}