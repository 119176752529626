<div class="p-grid frm-part">
    <!-- {{item | json}} -->
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idEmpleado')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-Emp" [(ngModel)]="item.idEmpleado" [options]="catEmpleados"
                optionLabel="nombreCompleto" [filter]="true" filterBy="nombreCompleto" optionValue="id"
                [autoDisplayFirst]="false" autoWidth="false" appendTo="body"
                (onChange)="validarUsuario(item.idEmpleado)">
            </p-dropdown>
            <label for="fl-Emp">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">* </span>
                <span>Empleado</span>
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('monto')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.monto" inputId="fl-monto" mode="currency" currency="MXN"
                [style]="{'width':'100%'}">
            </p-inputNumber>
            <label for="fl-monto">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">* </span>
                <span>Monto total</span>
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('periodo')">
        <br>
        <span class="p-float-label">
            <p-calendar [(ngModel)]="item.fecEdit" (ngModelChange)="fecha($event)" view="month" dateFormat="mm/yy"
                [readonlyInput]="true" inputId="fl-periodo" [yearRange]="yearRange" [yearNavigator]="true"
                dataType="string" [showIcon]="true"></p-calendar>
            <label for="fl-periodo">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">* </span>
                <span>Período</span>
            </label>
        </span>
    </div>

    <div class="p-sm-4 p-md-4 p-lg-4 p-xl-4 p-col-4" *ngIf="allowFormFields('comentario')">
        <br>
        <span class="p-float-label">
            <input type="text" id="fl-coment" pInputText [(ngModel)]="item.comentario" [pKeyFilter]="alphanumeric">
            <label for="fl-coment">Comentario</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fecIni') ">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecIni" view="month" dateFormat="yy/mm" dataType="string" [(ngModel)]="item.calendarIni"
                [yearNavigator]="true" [showIcon]="true" [yearRange]="yearRange" [monthNavigator]="true"
                 (onSelect)="configFecIni($event)">
            </p-calendar>
            <label for="fl-fecIni">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">* </span>
                <span>Fecha inicio</span>
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fecFin')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecFin" view="month" dateFormat="yy/mm" dataType="string" [(ngModel)]="item.calendarFin"
                [yearNavigator]="true" [showIcon]="true" [yearRange]="yearRange" [monthNavigator]="true"
                 (onSelect)="configFecFin($event)">
            </p-calendar>
            <label for="fl-fecFin">
                <span *ngIf="operationMode != OP_MODE.SEARCH" [style]="{'color':'red'}">* </span>
                <span>Fecha fin</span>
            </label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red; font-size: 0.7rem;">* Campos requeridos</span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode != OP_MODE.SEARCH && showButtonPagos">
        <p-button label="Configurar pagos" icon="pi pi-external-link" iconPos="right" (onClick)="showPagos = true">
        </p-button>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="allowFormFields('pagos')">
        <axks-descuento-infonavit-pagos [showDialog]="showPagos" [item]="item" [profile]="profile"
            [catFechas]="catFechas" (emitShowDialog)="showPagos = $event">
        </axks-descuento-infonavit-pagos>
        <br>
    </div>
</div>