import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs/internal/Observable';
import { TipoPago } from 'src/app/api/descuento/tipo-pago';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DescuentoPagosVariosRestcService extends ARestClient{
  

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msPagosVarios+ "/";
  }
  processBody(res: any) {
    let body = res ;
    return body || {};
  }

 
  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "";
    paths.remove = "remove";
    paths.search = "search";

    return paths;
  }
  catTipoPago(tipoPago: TipoPago): Observable<TipoPago>{
    let path = environment.msCatTipoPago;

    const headers = new HttpHeaders()
        .set('Access-Control-Allow-Origin', "*")
        .set('Access-Control-Allow-Headers', "*");
        
    return this.httpClient.post(path, tipoPago, {headers});
  }
  tipoPagoID(id: number): Observable<any> {
    let path = environment. msTipoPago+"/"+id;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers });
  }
  eliminarRegistro(id: any, idUserEmp: any): Observable<any> {
    let path = environment.msPagosVarios + "/" + id + "&" + idUserEmp;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.delete(path, { headers }) as Observable<any>;
  }
}  