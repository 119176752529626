import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { DescuentoRestcService } from 'src/app/client/descuento/descuento-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DescuentoPsService extends BaseProcess<DescuentoRestcService>{

  rest: DescuentoRestcService;
  
  constructor(httpClient: HttpClient, crest: DescuentoRestcService) {
    super(httpClient, crest);
    this.rest = crest;
  }

}
