import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PrestamosRestcService } from 'src/app/client/prestamos/prestamos-restc.service';
import { Prestamo } from '../../../../api/prestamo/prestamo';

@Component({
  selector: 'axks-prestamos-empleado-form',
  templateUrl: './prestamos-empleado-form.component.html',
  styleUrls: ['./prestamos-empleado-form.component.scss']
})
export class PrestamosEmpleadoFormComponent extends BaseView implements OnInit {

  @Input() item: Prestamo;

  yearRange: string;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.initYearRange();
  }

  initYearRange() {
    let now = new Date();
    this.yearRange = (now.getFullYear()- 10) + ":" + (now.getFullYear() + 2);
    console.log(this.yearRange);
  }
}