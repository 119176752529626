import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { MessageService } from 'primeng/api';
import { DescuentoInfonavit, Pago } from 'src/app/api/descuento/descuento-infonavit';

@Component({
  selector: 'axks-descuento-infonavit-pagos',
  templateUrl: './descuento-infonavit-pagos.component.html',
  styleUrls: ['./descuento-infonavit-pagos.component.scss']
})
export class DescuentoInfonavitPagosComponent implements OnInit {

  @Input() item: DescuentoInfonavit;
  @Input() profile: ProfileC;
  @Input() catFechas: string[];
  @Input() showDialog: boolean;

  @Output() emitShowDialog = new EventEmitter<boolean>();

  constructor(protected messageService: MessageService) { }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        pagos: []
      };
    }

    if (!this.catFechas) {
      this.catFechas = [];
    }
  }

  insertar() {
    if (this.item.idEmpleado) {
      this.item.pagos.push({});
    }
    else {
      this.messageService.add({
        severity: 'warn',
        summary: "Información faltante",
        detail: "No se ha seleccionado un empleado valido"
      });
    }
  }

  quitar(item: Pago) {
    this.item.pagos.splice(this.item.pagos.indexOf(item), 1);
  }

  ocultarDialog() {
    this.showDialog = false;
    this.emitShowDialog.emit(false);
  }


}
