<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<div class="p-grid">

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <p-table #tc [value]="deudas" class="result-list result" [resizableColumns]="true" [paginator]="true"
            [showCurrentPageReport]="true" [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]"
            [globalFilterFields]="['nombreEmpleado','deudaPrestamo', 'deudaImss', 'deudaInfonavit', 'total']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <span class="p-mr-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">
                        <button pButton type="button" pTooltip="Refrescar" icon="pi pi-refresh"
                            (click)="initCargaReportes()" tooltipPosition="bottom"></button>
                    </span>
                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tc.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'80%'}" />&nbsp;&nbsp;
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                            class="p-button-success" pTooltip="Exportar a excel" tooltipPosition="bottom"></button>
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idEmpleado">Núm. Empleado<p-sortIcon field="idEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombreEmpleado">Empleado<p-sortIcon field="nombreEmpleado"></p-sortIcon>
                    </th>
                    <th pSortableColumn="deudaPrestamo">Préstamos<p-sortIcon field="deudaPrestamo"></p-sortIcon>
                    </th>
                    <th pSortableColumn="deudaImss">IMSS<p-sortIcon field="deudaImss"></p-sortIcon>
                    </th>
                    <th pSortableColumn="deudaInfonavit">INFONAVIT<p-sortIcon field="deudaInfonavit"></p-sortIcon>
                    </th>
                    <th pSortableColumn="total">Total<p-sortIcon field="total"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr [pSelectableRow]="value">
                    <td>
                        {{value.idEmpleado}}
                    </td>
                    <td>
                        {{value.nombreEmpleado}}
                    </td>
                    <td>
                        {{value.deudaPrestamo | currency: "MXN"}}
                    </td>
                    <td>
                        {{value.deudaImss | currency: "MXN"}}
                    </td>
                    <td>
                        {{value.deudaInfonavit | currency: "MXN"}}
                    </td>
                    <td>
                        {{value.total | currency: "MXN"}}
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<p-toast></p-toast>