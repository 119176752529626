import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { PagoCalculado } from 'src/app/api/prestamo/prestamo-calculo-reg';
import { PrestamosRestcService } from 'src/app/client/prestamos/prestamos-restc.service';
import { TipoDescuento } from '../../../api/descuento/descuento';
import { Pago, Prestamo } from '../../../api/prestamo/prestamo';

@Component({
  selector: 'axks-prestamos-form',
  templateUrl: './prestamos-form.component.html',
  styleUrls: ['./prestamos-form.component.scss']
})
export class PrestamosFormComponent extends BaseView implements OnInit {

  @Input() value?: PagoCalculado;
  @Input() item: Prestamo;
  @Input() operationMode: OperationMode;
  @Input() profile: ProfileC;
  @Input() catTpDescuento: TipoDescuento[] = [];
  @Input() catEmpleados: EmpleadoNombre[] = [];
  @Input() showButtonPagos: boolean;
  @Input() catFechas: string[];

  PagoCal: PagoCalculado[];
  itemcalculado: PagoCalculado;
  autorizaCalculo: PagoCalculado[] = [];
  yearRange: any;
  showPagos: boolean;
  alphanumeric: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;
  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;
  op = OperationMode;
  isCalculate: boolean;

  @Output() onValueChange = new EventEmitter<PagoCalculado>();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private cal: PrestamosRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {};
    }

    this.initDates();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();
    this.yearRange = (year - 60) + ':' + (year + 60);
  }

  calcular(valor: any) {
    let calculado = {
      idEmpleado: this.item.idEmpleado,
      montoPrestamo: this.item.montoPrestamo,
      numPagos: this.item.numPagos,
    }

    if ((calculado.idEmpleado == null || calculado.idEmpleado == undefined) &&
      (calculado.montoPrestamo == null || calculado.montoPrestamo == undefined) &&
      (calculado.numPagos == null || calculado.numPagos == undefined)
    ) {
      this.warningMessage("Información faltante", "Favor de llenar los campos requeridos antes de calcular los pagos")
    }
    else {
      this.isCalculate = true;
      this.item.pagos = []
      this.cal.calculaValor(calculado).subscribe(
        (data) => {
          this.item.pagos = data as Pago[];
          this.isCalculate = false;
        },
        (error) => {
          this.errorMessage("Error", error.error.message);
          this.isCalculate = false;
        }
      );
    }
  }

  fechaPromesa(valor) {
    this.item.pagos[0].fecPromesaPago = valor
  }

}