import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { DescuentoImssRestcService } from 'src/app/client/descuento/descuento-imss-restc.service';

@Injectable({
  providedIn: 'root'
})
export class DescuentoImssPsService extends BaseProcess<DescuentoImssRestcService>{

  constructor(httpClient: HttpClient, crest: DescuentoImssRestcService) {
    super(httpClient, crest);
  }
}
