import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PrestamosPsService } from 'src/app/procs/prestamos/prestamos-ps.service';
import { DatePipe, formatCurrency } from '@angular/common';
import { Prestamo, Prestamomin } from '../../../../api/prestamo/prestamo';

@Component({
  selector: 'axks-prestamos-empleado',
  templateUrl: './prestamos-empleado.component.html',
  styleUrls: ['./prestamos-empleado.component.scss']
})
export class PrestamosEmpleadoComponent extends BaseMainComponent<PrestamosPsService> implements OnInit {

  tabDefs: TabDefinition[];

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: PrestamosPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
    this.initTabsMenu();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        pagos: [{}],
        idEmpleado: this.profile.idEmpleado
      } as Prestamo,
      minimumItem: {
        idEmpleado: this.profile.idEmpleado
      } as Prestamomin,
      registerItem: {
        pagos: [{}],
        idEmpleado: this.profile.idEmpleado
      } as Prestamo
    } as ComponentItem;
    return empty;
  }

  getService(): PrestamosPsService {
    return this.psService;
  }

  initTabsMenu() {
    this.tabDefs = [
      {
        groupId: 'Pagos',
        showAsTable: true
      }
    ] as TabDefinition[];
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      // Datos generales
      { header: 'ID Préstamo', field: 'id', inAll: true, groupId: 'Prestamo' },
      { header: 'Monto prestado', field: 'montoPrestamo', inAll: true, groupId: 'Prestamo', formatFunction: this.formatoMoneda },
      { header: 'Fecha de solicitud', field: 'fechaSolicitud', inAll: true, groupId: 'Prestamo', formatFunction: this.formatoFecha },
      { header: 'Monto pagado', field: 'montoPagado', inDetail: true, groupId: 'Prestamo', formatFunction: this.formatoMoneda },
      { header: 'Estatus', field: 'liquidado', inAll: true, groupId: 'Prestamo', formatFunction: this.formatBoolean },
      { header: 'Pagos realizados', field: 'descNumPagos', inDetail: true, groupId: 'Prestamo' },

      // Arreglo de pagos
      { header: 'Fecha de pago', field: 'fecPromesaPago', arrayFieldName: 'pagos', groupId: 'Pagos', inDetail: true, formatFunction: this.formatoFecha },
      { header: 'Monto', field: 'montoPromesaPago', arrayFieldName: 'pagos', groupId: 'Pagos', inDetail: true, formatFunction: this.formatoMoneda },
      { header: 'Comentario', field: 'comentario', arrayFieldName: 'pagos', groupId: 'Pagos', inDetail: true },
      { header: 'Pagado el', field: 'fechaPago', inDetail: true, arrayFieldName: 'pagos', groupId: 'Pagos', formatFunction: this.formatoFechaPagada }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Mis préstamos";
  }

  formatoFecha(value: string) {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy", "America/Mexico_City");
  }

  formatoFechaPagada(value: any) {
    if(value == null || value == undefined || value == '') return 'Pendiente de pago...';

    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy", "America/Mexico_City");
  }

  formatoMoneda(value: any) {
    return formatCurrency(value, "es-MX", "$");
  }

  formatBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Liquidado' : 'Con adeudo');
    }
    else {
      return '';
    }
  }

  cargarTabla() {
    this.showProgress = true;
    this.getService().find(this.item.item).subscribe(
      (data) => {
        this.showProgress = false;
        this.resultList = data;
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", "No se pudo cargar los prestamos");
      }
    );
  }
}