import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs/internal/Observable';
import { Aplazo } from 'src/app/api/descuento/aplazo';
import { DescuentoImss } from 'src/app/api/descuento/descuento-imss';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class DescuentoImssRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msImss + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let restPaths = super.getRestPaths();

    restPaths.detail = "";

    return restPaths;
  }

  consultarEmpleado(idEmp: any): Observable<any> {
    let path = environment.msBusquedaImss;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, idEmp, { headers }) as Observable<any>;
  }

  consultarFecha(id: any): Observable<any> {
    let path = environment.msFechasDrop + "/" + id;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<any>;
  }

  guardarPago(pagosEdit: DescuentoImss): Observable<DescuentoImss> {
    let path = environment.msImssPagos;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, pagosEdit, { headers }) as Observable<DescuentoImss>;

  }
}