import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrestamosComponent } from './views/prestamos/prestamos/prestamos.component';
import { TipoDescuentoComponent } from './views/descuento/tipo-descuento/tipo-descuento.component';
import { HomeComponent } from './views/home/home.component';
import { DescuentoImssComponent } from './views/descuento/descuento-imss/descuento-imss/descuento-imss.component';
import { DescuentoInfonavitComponent } from './views/descuento/descuento-infonavit/descuento-infonavit/descuento-infonavit.component';
import { DescuentoInfonavitEmpleadoComponent } from './views/descuento/descuento-infonavit/descuento-infonavit-empleado/descuento-infonavit-empleado.component';
import { DescuentoImssEmpleadoComponent } from './views/descuento/descuento-imss/descuento-imss-empleado/descuento-imss-empleado.component';
import { DepositosComponent } from './views/depositos/depositos/depositos.component';
import { TiposPagoComponent } from './views/pagos/tipos-pago/tipos-pago/tipos-pago.component';
import { PagoComponent } from './views/pagos/pago/pago/pago.component';
import { PrestamosEmpleadoComponent } from './views/prestamos/prestamos-empleado/prestamos-empleado/prestamos-empleado.component';
import { ReportesComponent } from './views/reportes/reportes/reportes.component';
import { OtrosDescuentosComponent } from './views/descuento/otros-descuentos/otros-descuentos/otros-descuentos.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'prestamo', component: PrestamosComponent },
  { path: 'tipo-desc', component: TipoDescuentoComponent },
  { path: 'prestamo-empleado', component: PrestamosEmpleadoComponent },
  { path: 'descuento-empleado-imss', component: DescuentoImssEmpleadoComponent },
  { path: 'descuento-empleado-infonavit', component: DescuentoInfonavitEmpleadoComponent },
  { path: 'descuento-imss', component: DescuentoImssComponent },
  { path: 'descuento-infonavit', component: DescuentoInfonavitComponent },
  { path: 'pagos', component: PagoComponent },
  { path: 'tipo-pago', component: TiposPagoComponent },
  { path: 'depositos', component: DepositosComponent },
  { path: 'reportes', component: ReportesComponent },
  { path: 'otros-descuentos', component: OtrosDescuentosComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
