import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PrestamosPsService } from 'src/app/procs/prestamos/prestamos-ps.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { Prestamo, PrestamoReg, Pago } from 'src/app/api/prestamo/prestamo';
import { PagoCalculado } from 'src/app/api/prestamo/prestamo-calculo-reg';
import { TipoDescuento } from 'src/app/api/descuento/descuento';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { TipoDescuentoCatRestcService } from 'src/app/client/descuento/tipo-descuento-cat-restc.service';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { DateToolsRestcService } from 'src/app/client/date-tools/date-tools-restc.service';

@Component({
  selector: 'axks-prestamos',
  templateUrl: './prestamos.component.html',
  styleUrls: ['./prestamos.component.scss'],
  providers: [MessageService]
})
export class PrestamosComponent extends BaseMainComponent<PrestamosPsService> implements OnInit {

  value: PagoCalculado;
  virtualScroll: boolean = false;
  showForms: boolean = false;
  newItem: PagoCalculado;
  tabDefs: TabDefinition[];
  showDialog: boolean = false;
  pagoSelect: Pago
  catTpDescuento: TipoDescuento[];
  catEmpleados: EmpleadoNombre[];
  catFechas: string[];
  OP_MODE = OperationMode;
  showButtonPagos: boolean;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: PrestamosPsService,
    private catRest: TipoDescuentoCatRestcService,
    private clientEmpPS: EmpleadoRestcService,
    private clientDateTools: DateToolsRestcService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
    if (!this.value) {
      this.value = {} as PagoCalculado;
    }

    this.initTabsMenu();

    this.showDialog = false;
    this.initCatalogos();
  }

  initCatalogos() {
    this.catRest.catTipoDescuento().subscribe(
      data => {
        this.catTpDescuento = data as TipoDescuento[];
        this.catTpDescuento.push({ idTipoDescuento: data.idTipoDescuento })
      }
    );

    // Catalogo de empleados
    this.clientEmpPS.getEmpleadosActivos(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error empleados", "No se pudo recuperar el catalogo de empleados");
      }
    );
  }

  initEmptyItem(): ComponentItem {
    let prof = this.getProfile() as ProfileC;
    let empty = {
      item: {
        pagos: [],
        idEmpleadoUser: prof.idEmpleado
      } as Prestamo,
      minimumItem: {} as Prestamo,
      registerItem: {
        pagos: [],
        idEmpleadoUser: prof.idEmpleado
      } as PrestamoReg
    } as ComponentItem;
    return empty;
  }

  getService(): PrestamosPsService {
    return this.psService;
  }

  initTabsMenu() {
    this.tabDefs = [
      {
        groupId: 'Pagos',
        showAsTable: true,
        onTableRowSelect: this.mostrarDialog.bind(this)
      }
    ] as TabDefinition[];
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'ID Préstamo', field: 'id', inAll: true, inResultList: true },
      { header: 'Nombre del empleado', field: 'nombreEmpleado', inAll: true, inResultList: true },
      { header: 'Monto Prestado', field: 'montoPrestamo', inAll: true, formatFunction: this.formatoMoneda },
      { header: 'Fecha Solicitud', field: 'fechaSolicitud', inAll: false, inResultList: false, inDetail: true, formatFunction: this.formatoFecha },
      { header: 'Monto pagado', field: 'montoPagado', inDetail: true, inResultList: false, formatFunction: this.formatoMoneda },
      { header: 'Estatus', field: 'liquidado', inDetail: true, inResultList: true, formatFunction: this.formatBoolean },
      { header: 'Pagos realizados', field: 'descNumPagos', inAll: false, inResultList: false, inDetail: true },

      { header: 'Fecha de pago', field: 'fecPromesaPago', arrayFieldName: 'pagos', groupId: 'Pagos', inAll: false, inResultList: false, inDetail: true, formatFunction: this.formatoFecha },
      { header: 'Monto', field: 'montoPromesaPago', arrayFieldName: 'pagos', groupId: 'Pagos', inDetail: true, inResultList: false, formatFunction: this.formatoMoneda },
      { header: 'Comentario', field: 'comentario', arrayFieldName: 'pagos', groupId: 'Pagos', inAll: false, inResultList: false, inDetail: true },
      { header: 'Pagado el', field: 'fechaPago', inDetail: true, arrayFieldName: 'pagos', groupId: 'Pagos', formatFunction: this.formatoFechaPagada }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Préstamos";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = [
      "idEmpleado", "montoPrestamo", "fecPromesaPago", "liquidado "
    ];
    this.detailFields = [];
  }

  formatoFecha(value: string) {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy", "America/Mexico_City");
  }

  formatoFechaPagada(value: any) {
    if (value == null || value == undefined || value == '') return 'Pendiente de pago...';

    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy", "America/Mexico_City");
  }

  formatoMoneda(value: any) {
    let pipe = new CurrencyPipe("es-MX");
    return pipe.transform(value, 'MXN');
  }

  formatBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Liquidado' : 'Con adeudo');
    }
    else {
      return '';
    }
  }

  cargarTabla() {
    this.getService().find(this.item.item).subscribe(
      (data) => {
        this.resultList = data;
      },
    );
  }

  save(event: any): void {
    
    this.showProgress = true;
    super.save(event);
    setTimeout(() => {
      this.showProgress = false;
    }, 2500);
  }

  edit(event: any): void {
    this.validarUsuario(this.item.item.idEmpleado);
    super.edit(event);
  }

  mostrarDialog(event: any) {
    this.pagoSelect = event;
    this.showDialog = true;
  }

  validarUsuario(idEmpleado: number) {
    if (this.operationMode != this.OP_MODE.SEARCH) {
      this.clientDateTools.fechasPago(idEmpleado).subscribe(
        (data) => {
          this.catFechas = data;
          this.showButtonPagos = true;
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Empleado no valido ', detail: error.error.message });
          this.showButtonPagos = false;
        }
      );
    }
  }

}




