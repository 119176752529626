import { Component, Input, OnInit } from '@angular/core';
import { BaseView, SessionClientProvider, OperationScreenModeService } from '@axks/components';
import { MessageService } from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TipoDescuentoReg } from '../../../api/descuento/descuento';

@Component({
  selector: 'axks-tipo-descuento-form',
  templateUrl: './tipo-descuento-form.component.html',
  styleUrls: ['./tipo-descuento-form.component.scss']
})
export class TipoDescuentoFormComponent extends BaseView implements OnInit {

  @Input() item: TipoDescuentoReg;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider
    ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {}
    }
  }

}
