<!-- {{item | json}} -->
<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('monto')">
        <!-- <br> -->
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.monto" inputId="fl-monto" mode="currency" currency="MXN" locale="es-MX">
            </p-inputNumber>
            <label for="fl-monto">Monto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('concepto')">
        <span class="p-float-label">
            <input type="text" id="fl-concepto" pInputText [(ngModel)]="item.concepto">
            <label for="fl-concepto">Concepto</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecDeposito')">
        <!-- <br> -->
        <span class="p-float-label">
            <p-calendar inputId="fl-fecDep" [(ngModel)]="item.fechaDeposito" dateFormat="yy/mm/dd" [showIcon]="true"
                dataType="string"></p-calendar>
            <label for="fl-fecDeo">Fecha de deposito</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('conciliable')">
        <!-- <br> -->
        <!-- <div><label>Conciliable: </label>
            <p-inputSwitch [(ngModel)]="item.conciliable"></p-inputSwitch>
        </div> -->
        <p-toggleButton [(ngModel)]="item.conciliable" onLabel="Conciliable" offLabel="No Conciliable" onIcon="pi pi-check" offIcon="pi pi-times" [style]="{'width': '13em'}"></p-toggleButton>
    </div>

    <!--  <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecInicioField')">
        <br>
        <span class="p-float-label">
            <p-calendar inputId="fl-fecIni" dateFormat="yy/mm/dd" [showIcon]="true" dataType="string"></p-calendar>
            <label for="fl-fecIni">Fecha Inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecFinField')">
        <br>
        <span class="p-float-label">
            <span class="p-float-label">
                <p-calendar inputId="fl-fecFin" dateFormat="yy/mm/dd" [showIcon]="true" dataType="string"></p-calendar>
                <label for="fl-fecFin">Fecha Fin</label>
            </span>
        </span>
    </div> -->

    <p-toast></p-toast>
</div>