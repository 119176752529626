import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Depositos } from 'src/app/api/depositos/deposito';

@Component({
  selector: 'axks-depositos-form',
  templateUrl: './depositos-form.component.html',
  styleUrls: ['./depositos-form.component.scss']
})
export class DepositosFormComponent extends BaseView implements OnInit {

  @Input() item: Depositos;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }
  }

}
